<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Актуальное</h5>

            <tricolor-line class="tricolor_line"/>

            <tabs-header class="card_containet__tabs" :section="news" />
            <p class="card_line"></p>
            <div class="block_photo_card">
              <Carousel class="block_card" :count="2" key="card">
                  <dynamic-news-card v-if="getPublicNews.length > 0"
                                     :is-list-view="false"
                                     :content="getPublicNews[0]"
                                     :key="`newsCard`"
                                     :class="{'width_card_one': getPublicNews.length === 1 }"/>

                  <dynamic-news-card v-if="getPublicNews.length > 1"
                                     :is-list-view="false"
                                     :content="getPublicNews[1]"
                                     :key="`news-Card`"/>
              </Carousel>
                <div class="news__important-container">
                    <activities-photo-news-dynamic class="card_important" :card="getPublicNews[2]" v-if="getPublicNews.length > 2"/>
                    <activities-photo-news-dynamic class="card_important" :card="getPublicNews[3]" v-if="getPublicNews.length > 3"/>
                    <activities-photo-news-dynamic class="card_important" :card="getPublicNews[4]" v-if="getPublicNews.length > 4"/>
                </div>
            </div>

            <div class="block_card">
                <div class="card_title-link">
                    <tabs-header class="card_containet__tabs" :section="surveys" />
                    <p class="card_line"></p>
                    <div class="contain">
                        <bills-card v-for="(survey, key) in surveys.templated" :key="`link${key}`" :card="survey" class="containet_block"/>
                    </div>
                </div>
                <div class="card_title-link">
                    <tabs-header class="card_containet__tabs" :section="teams" />
                    <p class="card_line"></p>
                    <div class="contain">
                        <bills-card v-for="(survey, key) in getPublicTopics" :key="`link${key}`" :card="survey" class="containet_block"/>
                    </div>
                </div>
                <div class="card_title-link" v-if="$store.getters.getPolls.length>0">
                    <tabs-header class="card_containet__tabs" :section="questionnaire" />
                    <p class="card_line"></p>
                    <div class="contain">
                        <survey-maps v-for="(survey, key) in $store.getters.getPolls" :key="`link${key}`" :card="{
                          date: survey.date,
                          title: survey.title,
                          text: survey.description,
                          type: 'question.svg',
                          link: `/feedback/${survey.id}`
                        }" class="containet_block"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import TabsHeader from '@/components/indexPage/TabsHeader'
import SurveyMaps from '@/components/activitiesSurveys/SurveyMaps'
import BillsCard from '@/components/activitiesSurveys/BillsCard'
import Carousel from '@/components/Carousel'
import ActivitiesPhotoNewsDynamic from '@/components/activitiesSurveys/ActivitiesPhotoNewsDynamic'
import formatDate from '@/mixins/formatDate'
import DynamicNewsCard from '@/components/newsList/DynamicNewsCard'

export default {
  name: 'Actual',
  mixins: [formatDate],
  components: {
    DynamicNewsCard,
    ActivitiesPhotoNewsDynamic,
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'tabs-header': TabsHeader,
    'survey-maps': SurveyMaps,
    'bills-card': BillsCard,
    Carousel: Carousel
  },
  data () {
    return {
      currentPage: 1,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '#'
        },
        {
          title: 'Актуальное'
        }
      ],
      news: {
        title: 'Последние новости',
        url_link: '/news?tab=News',
        url_name: 'Посмотреть все',
        templated: [
          {
            date: '11.08.2020',
            title: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            text: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'ativity1.png'
          },
          {
            date: '11.08.2020',
            title: 'Форумы «Сообщество» в Сочи и Барнауле перенесены на более поздний срок',
            text: 'Решение принято в связи с неблагоприятной эпидемиологической обстановкой',
            photo: 'ativity2.png'
          },
          {
            date: '11.08.2020',
            title: '«Давайте не будем стесняться говорить бизнесу спасибо!» — Вадим Ковалев',
            text: '«Давайте не будем стесняться говорить бизнесу спасибо!» — Вадим Ковалев',
            photo: 'slider1.png'
          },
          {
            date: '11.08.2020',
            title: 'Всероссийский молодежный форум «Восток»: объединить молодых лидеров со всей страны',
            text: 'Всероссийский молодежный форум «Восток»: объединить молодых лидеров со всей страны',
            photo: 'news14.png'
          },
          {
            date: '11.08.2020',
            title: 'Экоферма в Алтайском крае под угрозой из-за перекрытой дачниками дороги',
            text: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'news2.png'
          },
          {
            date: '11.08.2020',
            title: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            text: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'ativity1.png'
          },
          {
            date: '11.08.2020',
            title: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            text: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'ativity1.png'
          }
        ]
      },
      surveys: {
        title: 'Законопроекты для обсуждения',
        url_link: '/public-examination-bills',
        url_name: 'Посмотреть все',
        templated: [
          // {
          //   date: '11.08.2020',
          //   title: 'Законопроект о противодействии коррупции',
          //   text: 'Новый законопроект относится к противодействию коррупции на территории РФ и ',
          //   photo: 'hammer.svg',
          //   link: '/adopted_bill'
          // },
          // {
          //   date: '11.08.2020',
          //   title: 'Законопроект о противодействии коррупции',
          //   text: 'Новый законопроект относится к противодействию коррупции на территории РФ и ',
          //   photo: 'hammer.svg',
          //   link: '/adopted_bill'
          // }
        ]
      },
      teams: {
        title: 'актуальные темы',
        url_link: '/actuals_topic',
        url_name: 'Посмотреть все',
        templated: [
          {
            date: '11.08.2020',
            title: 'Законопроект о противодействии коррупции',
            text: 'Новый законопроект относится к противодействию коррупции на территории РФ и ',
            photo: 'thunderbolt.svg',
            link: '/current_topic'
          }
        //   {
        //     date: '11.08.2020',
        //     title: 'Законопроект о противодействии коррупции',
        //     text: 'Новый законопроект относится к противодействию коррупции на территории РФ и ',
        //     photo: 'thunderbolt.svg'
        //   }
        ]
      },
      questionnaire: {
        title: 'Текущие опросы',
        url_link: '/feedback',
        // url_link: '/surveys_activities',
        url_name: 'Посмотреть все',
        templated: [
          {
            date: '11.08.2020',
            title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
            text: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ',
            type: 'question.svg',
            link: '/feedback/30'
          },
          {
            date: '11.08.2020',
            title: 'Развитие физической культуры и спорта на селе',
            text: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ',
            type: 'question.svg',
            link: '/feedback/28'
          },
          {
            date: '11.08.2020',
            title: 'Социальный контракт: плюсы и минусы',
            text: 'Уважаемый респондент, Общественная Палата РФ и Российский государственный социальный',
            type: 'question.svg',
            link: '/feedback/28'
          }
        ]
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('setBillDraft')
    this.$store.dispatch('setPolls')

    this.$store.commit('setFilterSystemTagList', 'actual')
    this.$store.dispatch('getPublicNews')
    this.$store.commit('setFilterSystemTagList', null)

    this.$store.dispatch('getPublicTopics')
  },
  computed: {
    getPublicTopics () {
      return this.$store.getters.getPublicTopics.slice(0, 1).map((topic) => {
        return {
          title: topic.title,
          date: topic.date,
          text: topic.description,
          photo: 'thunderbolt.svg',
          link: '/actuals_topic/' + topic.id
        }
      })
    },
    getPublicNews () {
      return this.$store.getters.getPublicNews
    },
    getBillDraft () {
      return this.$store.getters.getBillDraft
    },
    getListView () {
      return this.$store.getters.getListView
    }
  },
  watch: {
    getBillDraft () {
      this.setBillDraft()
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    },
    setBillDraft () {
      this.surveys.templated.length = 0
      // this.countPage = this.$store.getters.getBillDraft.total
      this.$store.getters.getBillDraft.forEach(item => {
        this.surveys.templated.push({
          ...item,
          link: `/adopted_bill/${item.id}`,
          photo: 'hammer.svg'
        })
      })
      this.surveys.templated = this.surveys.templated.slice(0, 2)
    }
  },
  mounted () {
    document.title = 'Актуальное'
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}
.tricolor_line{
    margin-bottom: 0.75rem;
}
.block_photo_card{
    display: flex;
    margin-bottom: 1.88rem;
    justify-content: space-between;
}

.news__important-container{
    width: 26.625rem;
    flex-direction: column;
    flex-wrap: nowrap;
}
.card_important{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
}
.news__important-container  .card_important:nth-child(3){
    border: 0;
}

.block_card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    *:first-child {
        margin-right: 1rem;
    }
}
.card_title-link:nth-child(1){
    /*margin-right: 2.1rem*/
}
.contain{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.06rem;
    .containet_block{
        margin: 0 1.06rem 1.87rem 1.06rem;
        height: 14.9375rem;
        width: 24rem;//calc(33.333% - 2.125rem);
    }
    // justify-content: space-between;
}

.card_containet__tabs{
    margin-top: 1.44rem;
}
.card_line{
    background: #D5D6D9;
    border-radius: .125rem;
    height: .125rem;
    width: 100%;
    margin: .94rem 0 1.375rem 0;
}

.info-news {
//   margin-bottom: 1.88rem;
  width: 26.56rem;
  height: 23.44rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.38rem;
  &:nth-child(2){ margin: 0 2.19rem; }

  &__type {
    width: 100%;
    height: 14.38rem;

    margin-bottom: 1.25rem;
    border-radius: .5rem;
  }
  &__date{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #5E686C;
    opacity: 0.8;
    margin-bottom: .625rem;
  }

  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
    margin-bottom: .312rem;
  }

  &__text{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #171717;
    opacity: 0.8;
  }

}
.button_block {
    display: flex;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .info-news {
    width: 20.56rem;
 }
  .news__important-container {
    display: none;
  }
  .contain .containet_block {
    width: 45%;
  }
  .contain .containet_block:nth-child(n+3) {
    display: none;
  }
  .block_card{
      .card_title-link{
        width: 100%;
      }
      .card_title-link[data-v-1c521664]:nth-child(1) {
          margin-right: 0;
      }
  }

  .block_card{
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
        ::v-deep .carousel a article {
            width: 24rem;
        }
        ::v-deep .width_card_one  article {
            width: 22.95rem !important;
        }
        .card_title-link {
            margin-right: 0;
        }
    }
}
@media screen and (max-width: 420px){
    .white-container{
        width: calc(100% - 1px);
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .contain .containet_block {
      width: 100%;
      height: 15rem;
    }
    .info-news {
      width: 22.9rem;
      scroll-snap-align: start;
      flex-shrink: 0;
    }
    .block_card{
      display: inline-flex;
      justify-content: space-between;
      // overflow: auto;
      width: 100%;
        // :first-child {
        //     margin-right: 0;
        // }
        ::v-deep .carousel a article {
              width: 18rem;
        }
        ::v-deep .width_card_one  article {
            width: 22.95rem !important;
        }
        .card_title-link {
            margin-right: 0;
        }
        .card_title-link:not(:first-child) {
          // display: none;
        }
    }
    .block_card{
        ::v-deep .width_card_one  article {
            width: 22.95rem !important;
        }
    }
}

</style>
