<template>
  <div class="section_wrapper">
    <p v-text="section.title.toUpperCase()"></p>
    <ul v-if="section.tabs" class="desktop">
      <li v-for="(item, index) in section.tabs" :key="`key${index}`" :class="{'active': isActive === index}" v-text="item" @click="click(index)"></li>
    </ul>
    <div v-if="section.tabs" class="mobile">
      <p class="current" @click="toggleButton">
        <span>{{ section.tabs[isActive] }}</span>
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'isOpen': isTabOpen}">
          <rect x="11.1543" y="5.05469" width="7.00006" height="2.00002" rx="1" transform="rotate(134.83 11.1543 5.05469)" fill="#5A79A8"/>
          <rect x="2.66504" y="3.66406" width="7.00006" height="2.00002" rx="1" transform="rotate(44.8295 2.66504 3.66406)" fill="#5A79A8"/>
        </svg>
      </p>
      <div class="tabs-list" v-if="isTabOpen">
        <p v-for="(item, index) in section.tabs"
            :key="`mobileKey${index}`"
            @click="click(index)">
          {{ item }}
        </p>
      </div>
    </div>
    <router-link v-if="section.url_link && section.url_name" class="section_wrapper__link" :to="section.url_link">
      {{ section.url_name }}
      <span></span></router-link>
    <router-link v-if="section.url_link && section.url_name" class="section_wrapper__link-mobile" :to="section.url_link"></router-link>
  </div>
</template>
<script>

export default {
  name: 'TabsHeader',
  props: {
    section: {
      type: Object
    }
  },
  data () {
    return {
      isActive: 0,
      isTabOpen: false
    }
  },
  methods: {
    click (index) {
      this.isActive = index
      this.$emit('click', index)
      this.isTabOpen = false
    },
    toggleButton () {
      this.isTabOpen = !this.isTabOpen
    }
  }
}

</script>

<style scoped lang="scss">

.section_wrapper {
  display: flex;
  font-weight: normal;
  font-size: 0.875rem;

  color: #246CB7;

  .mobile {
    display: none;
  }

  .desktop {
    display: flex;
  }

  p {
    color: #1F3245;
    mix-blend-mode: normal;
    font-weight: 600;
    opacity: 0.8;
  }
  ul {
    display: flex;
    font-weight: normal;
    li {
      margin-left: 1.375rem;
      cursor: pointer;
    }
    &::before{
      content: "/";
      margin-left: 1.375rem;
    }
    .active{
      font-weight: 600;
      color: #003E78;
    }
  }
  &__link, &__link-mobile{
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #246CB7;
    mix-blend-mode: normal;
    align-items: flex-end;

    span{
      background: url('../../assets/icons/arrow.svg');
      background-size: cover;
      background-repeat: no-repeat;
      display: inline-block;
      transform: rotate(-90deg);
      width: 1rem;
      height: 1rem;
    }
  }
  &__link-mobile {
    display: none;
  }
}

@media screen and (max-width: 420px){
  .section_wrapper {

    .mobile {
      display: flex;
      position: relative;

      .current {
        margin-left: 1rem;
        color: #246CB7;
        font-weight: normal;
        display: flex;
        flex-direction: row;
        align-items: center;

        > span {
          margin-right: .5rem;
        }

        > svg {
          transition: .35s;
        }
      }

      .tabs-list {
        position: absolute;
        left: 2.4rem;
        top: 2rem;
        background-color: white;
        z-index: 100;
        padding: .5rem;

        > p {
          margin-bottom: 1rem;
          color: #246CB7;
          font-weight: normal;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      &::before{
        content: "/";
        margin-left: 1.375rem;
        color: #1F3245;
        mix-blend-mode: normal;
        font-weight: 600;
        opacity: 0.8;
      }
    }

    .desktop {
      display: none;
    }

    ul {
      li {
        margin-left: 0.6rem;
      }
      &::before{
        content: "/";
        margin-left: 0.6rem;
      }
    }

    &__link{
      display: none;
      visibility: hidden;
      position: relative;
    }
    &__link-mobile {
      display: flex;
    }
      &__link-mobile::before {
        content: 'Все';
        visibility: visible;
        display: flex;
        justify-content: flex-end;
      }
      &__link-mobile::after{
        content: '';
        visibility: visible;
        background: url('../../assets/icons/arrow.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        transform: rotate(-90deg);
        width: 1rem;
        height: 1rem;
      }
  }

  .isOpen {
    transform: rotateZ(180deg);
  }
}

</style>
